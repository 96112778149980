import React from "react"
import Button from "../button"

const BannerGreen = props => {
  const isExternal = props.link.indexOf('http') === 0;

  return (
    <div className="c-education">
      <div className="u-wrapper">
          <div className="c-education__info">
            <h2
              className="c-education__ttl o-ttl o-ttl--40 o-ttl--hybrid u-text-center"
              dangerouslySetInnerHTML={{ __html: props.title }}
            />
            <p className="o-headline">{props.subtitle}</p>
            <div className="u-text-center">
              <Button
                to={!isExternal ? props.link : null}
                href={isExternal ? props.link : null}
                content={props.buttonText}
                type="primary"
              />
            </div>
          </div>
      </div>
    </div>
  )
}

export default BannerGreen;
