import React, {useState} from 'react';
import Button from '../button';
import Title from '../title';
import { getBucketUrl } from '../../service';

const TransformPotency = ({ data }) => {
  const title = "Transformando <strong>potencial</strong> em <strong>potência</strong>"
  const text = "Carregamos com a gente um propósito muito claro: ajudar o empreendedor a fazer melhores negócios. Para isso, não medimos esforços em tornar a indústria mais justa e igualitária para os empreendedores, nossas maiores razões.";
  const link = "/cultura";
  const thumb = getBucketUrl(data.videothumb.path);

  const [video, setVideo] = useState(false);

  return (
    <>
      <div className="u-wrapper">
        <div className="c-potency">
          <div className="c-potency__item">
            <div className="c-video" onClick={() => setVideo(true)}>
              <img src={thumb} alt={title} className="c-potency__img" />
            </div>
          </div>
          {video && (
          <div className="c-video__background">
            <div className="c-video__close" onClick={() => setVideo(!video)}></div>
            <iframe title="video" className="c-video__iframe" src="https://www.youtube.com/embed/oq8h5VUnwzU" frameBorder="0" allowFullScreen></iframe>
          </div>
          )}
          <div className="c-potency__item">
            <Title
              content={title}
            />
            <p>{text}</p>
            {link && (
              <Button href={link} content="Saiba mais" type="primary" />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default TransformPotency;
