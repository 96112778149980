import React from "react"
import Button from "../button"
import {getBucketUrl} from '../../service'

function BannerHero(props) {
  return (
    <>
      <div className="c-banner c-banner--hero">
        <picture>
          <img
            className="c-banner__img"
            src={getBucketUrl(props.data.highlight_image.path)}
            alt={props.data.highlight_text}
          />
        </picture>
        <div className="c-banner__infos">
          <h2 className="c-banner__ttl">{props.data.highlight_text}</h2>
          <Button
            href={props.data.highlight_button_link}
            content={props.data.highlight_button_text}
            type="secondary"
          />
        </div>
      </div>
    </>
  )
}

export default BannerHero
