import React from "react"
import Button from "../button"
import {getBucketUrl} from '../../service';
import Title from '../title';

const OurTeams = ({data}) => {
  const title = "Conheça nossos <strong>times!</strong>";
  const text = "Não somos formados por áreas ou departamentos. Nós temos times feitos de pessoas.";
  const image = getBucketUrl(data.teamsthumb.path);

  return (
    <div className="c-our-teams u-gray-light">
      <div className="c-our-teams__wrapper u-wrapper">
        <div className="c-our-teams__container-img">
          <img src={image} alt={title} className="c-our-teams__img" />
        </div>
        <div className="c-our-teams__info">
          <Title content={title} />
          <p>{text}</p>
          <div className="u-text-center">
            <Button to="/times" content="Conheça agora" type="primary" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurTeams
