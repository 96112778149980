import React, { useEffect, useState } from "react"
import Layout from "components/layout/layout"
import BannerHero from "components/bannerHero"
import TransformPotency from "components/transformPotency"
import OurTeams from "components/ourTeams"
import Thumbs from "components/thumbs"
import BannerGreen from "components/bannerGreen"
import Programs from "components/programs"

const IndexPage = () => {
  const [api, setApi] = useState({})

  useEffect(() => {
    const checkApiData = () => {
      if (window.apiData) {
        setApi(window.apiData)
      } else {
        setTimeout(checkApiData, 100)
      }
    }
    checkApiData()
  }, [])

  return (
    <Layout>
      <>
        {api && api.constructor === Object && Object.keys(api).length !== 0 && (
          <>
            <BannerHero data={api.singletons.home} />
            <TransformPotency data={api.singletons.home} />
            <OurTeams data={api.singletons.home} />
            <Thumbs
              title="Nossas <strong>iniciativas</strong>"
              thumbs={api.collections.initiatives}
            />
            <BannerGreen
              title="<strong>Educação:</strong> nosso maior investimento"
              subtitle="Desde o início da nossa história, valorizamos o conhecimento como forma principal de desenvolver nossas pessoas. Na página de educação, você verá uma série de indicações de leitura, cursos e vídeos."
              link="/educacao"
              buttonText="Quero aprender"
            />
            <Programs programs={api.collections.programs} />
          </>
        )}
      </>
    </Layout>
  )
}

export default IndexPage
